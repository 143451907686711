<template>
  <div class="container">
    <!-- <div class="divide"></div> -->
    <div class="tabs">
      <span @click="routerIndex()">公司新闻与董事长寄语 > </span>
      <span>新闻列表</span>
    </div>
    <div class="banner">
      <div class="title">公司新闻</div>
    </div>
    <div class="content">
      <div class="today">
        <div>
          <img class="big" src="../../assets/index/t13.png" alt="" />
        </div>
        <div class="block">
          <div
            class="flex"
            v-for="(item, i) in todayList"
            :key="i"
            @mouseover="hover(i)"
            @mouseleave="hoverLeave()"
          >
            <div class="left">
              <div class="day">{{ cropStr(item.gmtCreate,5,10) }}</div>
              <div class="year">{{ cropStr(item.gmtCreate,0,4) }}</div>
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="content">{{ item.intro }}</div>
              <div class="lookDetail" @click="viewDetail(item,i)">
                <span class="font" style="vertical-align: middle"
                  >查看详情</span
                >
                <img
                  v-show="!item.hover"
                  style="
                    vertical-align: middle;
                    margin-top: 2px;
                    margin-left: 5px;
                  "
                  src="../../assets/index/jt2.png"
                  alt=""
                />
                <img
                  v-show="item.hover"
                  style="
                    vertical-align: middle;
                    margin-top: 2px;
                    margin-left: 5px;
                  "
                  src="../../assets/index/jt1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="list">
          <el-card
            v-for="(item, i) in newsList"
            :key="i"
            :body-style="{ padding: '0px' }"
          >
            <div class="img">
              <img :src="item.coverImage" alt="" />
            </div>
            <div class="text">
              <div>
                <div class="name">{{ item.title }}</div>
                <div class="time">{{ item.gmtCreate }}</div>
              </div>
              <div class="more" @click="viewDetail(item,i)">
                <span>查看详情</span>
                <img src="../../assets/index/jt1.png" alt="" />
              </div>
            </div>
          </el-card>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="newsParam.pageNum"
            :page-size="newsParam.pageSize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      todayList: [
        {
          hover: false,
          day: "01-22",
          year: "2023",
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          time: "2022-21-22 12:11",
          readers: "1000",
        },
        {
          hover: false,
          day: "01-23",
          year: "2023",
          title: "新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "2022-21-22 12:11",
          readers: "1000",
        },
        {
          hover: false,
          day: "01-22",
          year: "2023",
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          time: "2022-21-22 12:11",
          readers: "1000",
        },
      ],
      // 新闻是否hover
      newsHover: false,
      newsList: [
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
        {
          name: "新闻名称",
          time: "2023-01-22 22:11",
          img: require("../../assets/index/t13.png"),
          title: "新闻名称新闻名称",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
          readers: "1000",
        },
      ],
      newsParam: {
        pageNum: 1,
        pageSize:9,
      },
      total: 10,
    };
  },
  mounted() {
    this, this.requestNewsList(this.newsParam);
  },
  methods: {
    hover(i) {
      this.newsList.forEach((item) => {
        item.hover = false;
        this.newsList[i].hover = true;
      });
    },

    hoverLeave() {
      this.newsList.forEach((item) => {
        item.hover = false;
      });
    },
     // 截取字符串
     cropStr(str,start,end){
      return String(str).slice(start,end)
    },
    // 调转到首页
    routerIndex() {
      this.$router.push("/news");
      this.$store.commit("changeIndex", 4);
    },
    // 请求新闻列表
    requestNewsList(state) {
      let param = state;
      this.$post("/web/new/pageList", param).then((res) => {
        // 主要参数
        if (res.code == 1) {
          // console.log(res.data);
          this.todayList = res.data.list.slice(0,3);
          this.newsList = res.data.list;
          this.total = res.data.total;
        } else {
          console.log("异常");
        }
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.newsParam.pageNum = val;
      this.requestProductList(this.newsParam);
    },
    viewDetail(item,i) {
      this.$router.push({
        name: "新闻内容详情",
        params: {
          item,
        },
      });
      this.$store.commit("changeTheme", false);
      localStorage.setItem('newsDetail',JSON.stringify(item))
      localStorage.setItem('index',i)
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
}
/* // 导航条 */
.tabs {
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 48px;
  font-size: 0.2rem;
  padding-top: 8px;
  margin-top: 1.1rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #565656;
    cursor: pointer;
  }
}
.banner {
  width: 100%;
  height: 5rem;
  background: linear-gradient(
      217deg,
      rgba(47, 158, 209, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(176deg, #6060bd 0%, rgba(103, 97, 194, 0) 100%),
    linear-gradient(336deg, rgba(80, 169, 224, 0.74), rgba(0, 0, 255, 0) 70.71%);
}

.banner .title {
  font-size: 0.68rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%);
}

.content {
  background-color: #fff;
  margin: 2rem 1.6rem;
}

.today {
  background-color: #fff;
  position: absolute;
  top: 3rem;
  width: 16rem;
  height: 6.34rem;
  box-sizing: border-box;
  padding: 0.78rem 0.58rem;
  display: flex;
}
.big {
  width: 7.08rem;
  height: 4.78rem;
}

.block {
  margin-left: 0.68rem;
  overflow-y: auto;
}
.flex {
  border-bottom: solid 2px #cccccc;
  padding: 0.2rem 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  border-right: solid 1px #d8d8d8;
  padding-right: 10px;
  box-sizing: border-box;
}
.day {
  white-space: nowrap;
  font-size: 1.98vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #272727;
  cursor: pointer;
}
.year {
  text-align: right;
  font-size: 0.94vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #272727;
  cursor: pointer;
}

.right {
  padding: 0 10px;
  box-sizing: border-box;
  width: 80%;
}
.right .title {
  font-size: 0.94vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3d3d3d;
  cursor: pointer;
}
.right .content {
  font-size: 0.83vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0a0a0;
  margin: 5px 0;
  cursor: pointer;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lookDetail {
  text-align: right;
  cursor: pointer;
}
.lookDetail span:hover {
  color: #127bf2;
}
.lookDetail span {
  font-size: 0.73vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3d3d3d;
}
.lookDetail img {
  height: 0.73vw;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 4.4rem 0.58rem 0 0.58rem;
}
.el-card {
  margin-bottom: 0.44rem;
}
.img {
  height: 2.24rem;
  overflow: hidden;
}
.img img {
  width: 4.39rem;
  height: 100%;
  object-fit: cover;
}
.text {
  box-sizing: border-box;
  height: 1.06rem;
  display: flex;
  justify-content: space-between;
  padding: 0.22rem;
  cursor: pointer;
}
.text .name {
  font-size: 0.2rem;
  font-weight: 600;
  color: #3d3d3d;
  line-height: 0.28rem;
}
.text .time {
  font-size: 0.16rem;
  color: #a0a0a0;
  line-height: 0.22rem;
  margin-top: 0.1rem;
}
.text .more {
  color: #127bf2;
  font-size: 14px;
  padding-top: 0.25rem;
}
.text .more span {
  margin-right: 0.1rem;
}

.pagination {
  display: flex;
  justify-content: center;
}
</style>
